@import url('https://fonts.googleapis.com/css?family=Merienda');

.App {
    background-color: #384c65;
    min-height: 100vh;
}

.cell {
    padding: 10px;
}

body, h1, h2, h3, h4, h5, h6{
    font-family: 'Merienda', cursive;
}