@import url(https://fonts.googleapis.com/css?family=Merienda);
.App {
    background-color: #384c65;
    min-height: 100vh;
}

.cell {
    padding: 10px;
}

body, h1, h2, h3, h4, h5, h6{
    font-family: 'Merienda', cursive;
}
#heading {
    width: 100%;
}

#heading div.background {
    height: 1000px;
    background-position: 60%;
    background-repeat: no-repeat;
    background-size: cover;
}

@media only screen and (min-width: 40em) {
    #heading div.background {
        height: 700px;
    }
}

#heading div.title {
    border-radius: 10px;
    background-color: rgba(56, 76, 101, 0.75);
    color: #e1faea;
    margin-top: 10px;
}

#heading div.title h1,
#heading div.title h2,
#heading div.title h3 {
    text-align: center;
}

#heading div.title a {
    color: #90e1e0;
}

ul {
    list-style: none;
}

div p:last-child {
    margin-bottom: 0;
}
#navbar {
    background-color: #384c65;
    color: #e1faea;
}

#navbar h1 a{
    text-align: left;
    margin-bottom: 0;
    color: #e1faea;
    cursor: pointer;
}

#navbar nav {
    text-align: right;
    float: right;
    height: 100%;
}

#navbar .menu {
    height: 100%;
    align-items: center;
}

#navbar .menu li a {
    padding: .7rem .4rem;
    color: #e1faea;
    background-color: #384c65;
    cursor: pointer;
    outline: none;
}

#navbar .menu-icon-container,
#navbar .menu-icon {
    display: none;
}

#navbar nav {
    align-items: center;
}

#navbar nav .menu-icon {
    margin-top: 12px;
    display: inline-block;
    outline: none;
}

#navbar nav ul li, nav:active ul li {
    display: none;
}

#navbar nav.show-menu ul,
#navbar nav.show-menu ul li {
    display: block;
}

.menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #e1faea;
    box-shadow: 0 7px 0 #e1faea, 0 14px 0 #e1faea;
    content: "";
}

.menu-icon:hover::after {
    background: #e1faea;
    box-shadow: 0 7px 0 #e1faea, 0 14px 0 #e1faea;
}


@media only screen and (min-width: 40em) {

    #navbar nav .menu-icon {
        display: none;
    }

    #navbar nav ul, nav:active ul,
    #navbar nav.show-menu ul {
        display: flex;
    }

    #navbar nav ul li,
    #navbar nav.show-menu ul li {
        display: block;
    }

    #navbar .menu li:first-of-type a {
        padding-left: 0;
    }

    #navbar .menu li:last-of-type a {
        padding-right: 0;
    }
}
