#heading {
    width: 100%;
}

#heading div.background {
    height: 1000px;
    background-position: 60%;
    background-repeat: no-repeat;
    background-size: cover;
}

@media only screen and (min-width: 40em) {
    #heading div.background {
        height: 700px;
    }
}

#heading div.title {
    border-radius: 10px;
    background-color: rgba(56, 76, 101, 0.75);
    color: #e1faea;
    margin-top: 10px;
}

#heading div.title h1,
#heading div.title h2,
#heading div.title h3 {
    text-align: center;
}

#heading div.title a {
    color: #90e1e0;
}

ul {
    list-style: none;
}

div p:last-child {
    margin-bottom: 0;
}